function show_noty(text, type)
{
	var n = noty({
		dismissQueue: true,
		force: false,
		maxVisible: 5,
		timeout: 2500,
		progressBar: true,
		layout: 'topCenter',
		theme: 'relax',
		modal: false,
		killer: false,
		closeWith: ['click'],
		buttons: false,
		type: type, // success, error, warning, information, notification
		text: text,
		animation: {
			open: {height: 'toggle'},
			close: {height: 'toggle'},
			easing: 'swing',
			speed: 500
		}
	});
	return(n);
}